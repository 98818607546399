import React from "react";
import { Form, Header } from "semantic-ui-react";
import {
  CustomForm,
  InputCustom,
  TextArea,
  BusSpecSelect,
} from "./newRequestForm";
import { GoBackBtn } from "../gobackbtn";
import "./requestFormStyles.css";
import { BusCalculator } from "../busCalculator";

export function BusSpecs(props) {
  return (
    <CustomForm
      stepchanger={() => props.onStepChange()}
      className="busRequestForm"
    >
      <Header as="h3">Let's get some bus details.</Header>
      <Form.Group widths="equal">
        <InputCustom
          name="amntOfBuses"
          labeltitle="How many buses do you need?"
          type="number"
        />
        <div className="bus-calculator">
          <BusCalculator />
        </div>
      </Form.Group>
      <InputCustom
        name="groupType"
        labeltitle="Group type (e.g., Sport/Grade/Organization)"
      />
      <Form.Group widths="equal">
        <InputCustom
          name="amntOfStudents"
          labeltitle="Number of students"
          type="number"
        />
        <InputCustom
          name="amntOfAdults"
          labeltitle="Number of adults"
          type="number"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <BusSpecSelect name="seatBelts" labeltitle="Seat Belts" />
        <BusSpecSelect name="storage" labeltitle="Storage" />
      </Form.Group>

      <TextArea
        name="specialInstructions"
        labeltitle="Special Instructions"
        placeholder="i.e park on NW corner of..."
      />
      <GoBackBtn goBack={props.prevStep} actionName="Next" />
    </CustomForm>
  );
}
