import React from "react";
import { Container } from "semantic-ui-react";
export function DriverApplicationSubmission() {
  return (
    <Container text className="busSubmissionThankyou">
      <h2>THANK YOU FOR YOUR INTEREST IN JOINING THE PACER FAMILY</h2>
      <p>
        We will review your submission and reach out as soon as possible. Thank
        you.
      </p>
    </Container>
  );
}
