import React from "react";
import { Container } from "semantic-ui-react";
export function BusSubmissionThankyou() {
  return (
    <Container text className="busSubmissionThankyou">
      <h2>THANK YOU FOR SUBMITTING A BUS REQUEST</h2>
      <p>
        Your request will be reviewed within 48 hours. Once reviewed you will
        receive an email with further instructions.
      </p>
    </Container>
  );
}
