import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "fomantic-ui-css/semantic.css";
import React from "react";
import { HomePage } from "./components/home";
import { RequestBus } from "./components/requestBusForm";
import { ContactForm } from "./components/contactForm";
import { Careers } from "./components/careers";
import { DriverApplication } from "./components/driverApplication";
import { ResponsiveContainer } from "./components/responsiveContainer";
import { BusSubmissionThankyou } from "./components/busSubmissionThankyou";
import { ContactPageSubmission } from "./components/contactPageSubmission";
import { DriverApplicationSubmission } from "./components/driverApplicationThankyou";
import { About } from "./components/about";
import { BusFleet } from "./components/busFleet";
import { ToolBox } from "./components/toolBox";
import { Footer } from "./components/footer";

import { StateMachineProvider, createStore } from "little-state-machine";

import "./App.css";

createStore({});

function App() {
  return (
    <StateMachineProvider>
      <Router>
        <ResponsiveContainer>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/requestBus" element={<RequestBus />} />
            <Route path="/contactUs" element={<ContactForm />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/driverApplication" element={<DriverApplication />} />
            <Route path="/about" element={<About />} />
            <Route path="/busFleet" element={<BusFleet />} />
            <Route path="/toolBox" element={<ToolBox />} />
            <Route path="/thankyou" element={<BusSubmissionThankyou />} />
            <Route
              path="/contactThankYou"
              element={<ContactPageSubmission />}
            />
            <Route
              path="/driverAppThankyou"
              element={<DriverApplicationSubmission />}
            />
            <Route render={() => <h1>404: page not found</h1>} />
          </Routes>
          <Footer />
        </ResponsiveContainer>
      </Router>
    </StateMachineProvider>
  );
}

export default App;
