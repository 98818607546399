import React from "react";
import { Segment, Header, Grid, Icon, Card } from "semantic-ui-react";

import PacerFleet2 from "../img/pacerFleet2.jpg";
import { BusCalculator } from "./busCalculator";

export function ToolBox(props) {
  return (
    <>
      <div>
        <div
          className="welcome-header"
          style={{ backgroundImage: `url(${PacerFleet2})` }}
        >
          <div className="overlay-background"></div>
          <div className="centered-welcome">
            <Header as="h1">Pacer Fleet</Header>
          </div>
        </div>
      </div>
      <Segment vertical className="intro-section">
        <Grid container stackable verticalAlign="middle" columns={3}>
          <Grid.Row>
            <Card.Group itemsPerRow={3}>
              <Card color="red">
                <Card.Content>
                  <Card.Description>
                    Not sure how many buses you need? Click on the calculator to
                    find out.
                    <Icon>
                      <BusCalculator />
                    </Icon>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card color="orange" />
              <Card color="yellow" />
            </Card.Group>
            {/* <Grid.Column>
              <BusCalculator name="calculator" size="huge" color="" />
            </Grid.Column>
            <Grid.Column>
              <Icon name="home" size="huge" />
            </Grid.Column>
            <Grid.Column>
              <Icon name="home" size="huge" />
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
