import React from "react";
import { Image, Grid, Header, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import BusWaltHall from "../img/busWaltHall.jpg";

export function IntroductionSection() {
  return (
    <Grid container stackable verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width="8">
          <Header as="h2">We Are a Family-Owned School Bus Company</Header>
          <p className="intro-section-paragraph">
            We take pride in having provided school bus transportation in the
            Los Angeles community for over 7 years, giving our riders a safe
            option to get to destinations across the Los Angeles region. Let us
            facilitate your school bus transportation needs.
          </p>
          <Header as="h2">Safe and Affordable</Header>
          <p className="intro-section-paragraph">
            Booking a school bus can cost you as little as $5 per student.
            School buses are one of the safest modes of transportation.
          </p>
        </Grid.Column>
        <Grid.Column width="6" floated="right">
          <Image src={BusWaltHall} circular size="large"></Image>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Button
            size="huge"
            className="request-button"
            as={Link}
            to="/requestBus"
          >
            Request A School Bus
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
