import React, { useState } from "react";
import {
  Container,
  Divider,
  Segment,
  Icon,
  Header,
  Image,
  Message,
} from "semantic-ui-react";
import { IntroductionSection } from "./intoductionSection";

import WelcomeBackground from "../img/homePageImage.jpg";

export function HomePage(props) {
  const [visible, setVisible] = useState(true);
  const handleDismiss = () => {
    setVisible(false);
  };
  return (
    <>
      {visible && (
        <Message
          attached
          icon="announcement"
          header="WE ARE NOW HIRING!"
          content={`
              Visit our careers page to
              apply.
           `}
          onDismiss={handleDismiss}
          className="hiring-banner"
        />
      )}
      <div>
        <Image src={WelcomeBackground} centered fluid />
      </div>
      <Segment vertical className="intro-section">
        <IntroductionSection />
      </Segment>
      <Container>
        <Divider horizontal>
          <Header as="h4">
            <Icon name="bus" />
          </Header>
        </Divider>
      </Container>

      <Segment vertical className="business-info-section"></Segment>
    </>
  );
}
