import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Media } from "./media";
import {
  Icon,
  Menu,
  Segment,
  Sidebar,
  Container,
  Image,
} from "semantic-ui-react";

import PacerLogo from "../img/pacerTransparentcanva.png";

export function SideBarMenu(props) {
  const [visible, setVisible] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const show = visible ? true : false;

  const handleMenuClick = (name) => {
    setActiveItem(name);
    setVisible(false);
  };

  return (
    <Media as={Sidebar.Pushable} at="mobile">
      <Sidebar.Pushable>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          inverted
          onHide={() => setVisible(false)}
          vertical
          visible={show}
          width="thin"
        >
          <Menu.Item
            name="home"
            active={activeItem === "home"}
            onClick={() => handleMenuClick("home")}
            as={Link}
            to="/"
          >
            <Icon name="home" />
            Home
          </Menu.Item>
          <Menu.Item
            name="requestBus"
            active={activeItem === "requestBus"}
            onClick={() => handleMenuClick("requestBus")}
            as={Link}
            to="/requestBus"
          >
            <Icon name="bus" />
            Request A Bus
          </Menu.Item>
          <Menu.Item
            name="contact"
            active={activeItem === "contact"}
            onClick={() => handleMenuClick("contact")}
            as={Link}
            to="/contactUs"
          >
            <Icon name="at" />
            Contact
          </Menu.Item>
          <Menu.Item
            name="about"
            active={activeItem === "about"}
            onClick={() => handleMenuClick("about")}
            as={Link}
            to="/about"
          >
            <Icon name="info" />
            About
          </Menu.Item>
          <Menu.Item
            name="careers"
            active={activeItem === "careers"}
            onClick={() => {
              handleMenuClick("careers");
            }}
            as={Link}
            to="/careers"
          >
            <Icon name="briefcase" />
            Careers
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={show}>
          <Segment
            inverted
            textAlign="center"
            style={{ padding: "1em 0em", background: "rgb(1, 123, 166)" }}
            vertical
          >
            <Menu inverted pointing secondary size="large">
              <Container>
                <Menu.Item position="left">
                  <Image
                    alt="logo"
                    src={PacerLogo}
                    className="logo"
                    style={{ width: "95px" }}
                  />
                </Menu.Item>
                <Menu.Item onClick={setVisible}>
                  <Icon name="sidebar" />
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
          {props.children}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Media>
  );
}
