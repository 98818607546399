import React, { useState } from "react";
import {
  Header,
  Icon,
  Modal,
  Container,
  Form,
  Popup,
  Divider,
} from "semantic-ui-react";

export function BusCalculator(props) {
  const [open, setOpen] = useState(false);
  const [busTotal, setBusTotal] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const { fourAndBelow, fiveAndAbove } = e.target.elements;
    const entryValues = {
      fourAndBelow: fourAndBelow.value,
      fiveAndAbove: fiveAndAbove.value,
    };
    if (entryValues) {
      const totalSeatsNeeded =
        Number(entryValues.fourAndBelow) / 3 +
        Number(entryValues.fiveAndAbove) / 2;
      const totalBusesNeeded = totalSeatsNeeded / 26;
      setBusTotal(Math.ceil(totalBusesNeeded));
    }
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(props.opencalculator)}
      onOpen={() => setOpen(props.opencalculator)}
      open={open}
      size="mini"
      trigger={
        <Popup
          inverted
          size="small"
          content="Click for help calculating number of buses needed."
          trigger={
            <Icon
              circular
              name="calculator"
              inverted
              color="yellow"
              size="large"
              onClick={() => setOpen(true)}
            />
          }
        ></Popup>
      }
    >
      <Header icon>
        <Icon name="calculator" />
        PACER BUS CALCULATOR
      </Header>
      <Modal.Content>
        <Container>
          <div className="contact-form-container">
            <Form className="contact-form" onSubmit={onSubmit}>
              <p>
                Fill out the form below to calculate how many buses your
                adventure will need.
              </p>
              <Divider />
              <Form.Input
                label="# of students 4th grade and under(10yrs & under)"
                type="number"
                name="fourAndBelow"
              />
              <Form.Input
                label="# of students 5th grade to adults"
                type="number"
                name="fiveAndAbove"
              />
              <Header as="h3">Estimated buses needed: {busTotal}</Header>
              <button className="contact-form-btn" type="submit">
                calculate
              </button>
            </Form>
          </div>
        </Container>
      </Modal.Content>
    </Modal>
  );
}
