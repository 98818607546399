import React from "react";
import { Segment, Header, Grid, Image } from "semantic-ui-react";

import PacerFleet2 from "../img/pacerFleet2.jpg";
import WaltHallBus3 from "../img/busWaltHall3.jpg";
import PacerBus from "../img/pacerFleetSide.jpg";

export function BusFleet(props) {
  return (
    <>
      <div>
        <div
          className="welcome-header"
          style={{ backgroundImage: `url(${PacerFleet2})` }}
        >
          <div className="overlay-background"></div>
          <div className="centered-welcome">
            <Header as="h1">Pacer Fleet</Header>
          </div>
        </div>
      </div>
      <Segment vertical className="intro-section">
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width="8">
              <Header as="h3">
                THE PACER FLEET
                <Header.Subheader>CHP CERTIFIED</Header.Subheader>
              </Header>
              <p className="intro-section-paragraph">
                Consists of all school buses that are certified yearly by the
                California Highway Patrol. Maintenance is performed every 3000
                miles or 45 days. Buses are inspected daily by the driver during
                their pre-trip inspection and any concerns are reported to a
                mechanic for repair.
              </p>
              <Header as="h3">BUS CAPACITY</Header>
              <p className="intro-section-paragraph">
                At 40 feet long we can transport up to 84 students(at 3 per
                seat).
              </p>
            </Grid.Column>
            <Grid.Column width="6" floated="right">
              <Image src={WaltHallBus3} size="big" rounded></Image>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="6" floated="left">
              <Image src={PacerBus} size="big" rounded></Image>
            </Grid.Column>
            <Grid.Column width="8">
              <Header as="h3">Pacer Buses</Header>
              <p className="intro-section-paragraph">
                Our fleet consists of all school buses that are certified by the
                California Highway Patrol yearly. Maintenance is performed every
                3000 miles or 45 days. Buses are inspected daily by the driver
                during their pre-trip inspection and any concerns are reported
                to a mechanic for repair.
              </p>
              <Header as="h3">
                PACER REGISTRANTS
                <Header.Subheader>
                  USDOT 2737387, TCP 39554, CA 567142
                </Header.Subheader>
              </Header>
              <p className="intro-section-paragraph">
                Our buses can transport up to 80 students.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
