import React from "react";
import { Header } from "semantic-ui-react";
import { CustomForm, InputCustom, CustomPhoneInput } from "./newRequestForm";
import { GoBackBtn } from "../gobackbtn";
import "./requestFormStyles.css";

export function BillingInfo(props) {
  return (
    <CustomForm
      stepchanger={() => props.onStepChange()}
      className="busRequestForm"
    >
      <Header as="h3">Enter Billing Information.</Header>
      <InputCustom name="organizationName" labeltitle="Organization" />
      <InputCustom name="attentionToName" labeltitle="Attention To" />
      <InputCustom
        name="billingStreetAdress"
        labeltitle="Billing Street Address"
      ></InputCustom>
      <InputCustom name="billingCity" labeltitle="City"></InputCustom>
      <InputCustom name="billingZipcode" labeltitle="Zip Code"></InputCustom>
      <InputCustom name="billingEmail" labeltitle="Email" />
      <CustomPhoneInput name="billingPhoneNumber" labeltitle="Phone Number" />
      <GoBackBtn goBack={props.prevStep} actionName="Next" />
    </CustomForm>
  );
}
