import React from "react";
import { Grid } from "semantic-ui-react";

export function GoBackBtn(props) {
  const goBack = (e) => {
    e.preventDefault();
    props.goBack();
  };
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <button className="go-back-btn" onClick={goBack}>
            Back
          </button>
        </Grid.Column>
        <Grid.Column>
          <button type="submit">{props.actionName}</button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
