import React, { useState } from "react";
import { Header, Item, Modal, Container, List } from "semantic-ui-react";

export function TermsAndConditions(props) {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      closeIcon
      onClose={() => setOpen()}
      onOpen={() => setOpen()}
      open={open}
      size="tiny"
      trigger={
        <span onClick={() => setOpen(true)} className="terms-conditions">
          Terms And Conditions
        </span>
      }
    >
      <Header icon>PACER TERMS AND CONDITIONS</Header>
      <Modal.Content>
        <Container>
          <Item.Group>
            <Item>
              <Item.Content>
                <Item.Header as="a">Cancellation Policy</Item.Header>
                <Item.Description>
                  <List bulleted>
                    <List.Item>
                      All cancellations must be submitted by email, and must be
                      requested by the purchaser.
                    </List.Item>
                    <List.Item>
                      Pacer will reply by email acceptance of your cancellation,
                      and of charges, if any.
                      <List.List>
                        <List.Item>
                          Same day cancellation is the full trip charge.
                        </List.Item>
                        <List.Item>
                          Day before the trip is a 25% charge 2 days before the
                          trip the charge is $75
                        </List.Item>
                      </List.List>
                    </List.Item>
                  </List>
                </Item.Description>
              </Item.Content>
            </Item>

            <Item>
              <Item.Content>
                <Item.Header as="a">Passenger Code of Conduct</Item.Header>
                <Item.Description>
                  {" "}
                  <List bulleted>
                    <List.Item>
                      Pacer is NOT responsible for any lost items left on the
                      bus.
                    </List.Item>
                    <List.Item>
                      Passengers are to remain seated at ALL times.
                    </List.Item>
                    <List.Item>
                      NO SMOKING on the bus or in proximity thereof.
                    </List.Item>
                    <List.Item>DO NOT open or close bus doors.</List.Item>
                    <List.Item>
                      DO NOT touch anything around the driver's seat.
                    </List.Item>
                    <List.Item>
                      NO eating or drinking unless prior arrangements have been
                      made.
                    </List.Item>
                  </List>
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Container>
      </Modal.Content>
      <Modal.Header></Modal.Header>
    </Modal>
  );
}
