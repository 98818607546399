import React from "react";
import { Icon, Step } from "semantic-ui-react";

export function FormSteps(props) {
  const { step } = props.currentStep;
  return (
    <Step.Group className="form-steps" size="mini" attached="top">
      <Step
        name="1"
        active={step === 1}
        completed={step > 1}
        disabled={1 > step}
      >
        <Icon name="address card" />
        <Step.Content>
          <Step.Title>Contact</Step.Title>
        </Step.Content>
      </Step>

      <Step
        name="step2"
        active={step === 2}
        completed={step > 2}
        disabled={2 > step}
      >
        <Icon name="bus" />
        <Step.Content>
          <Step.Title>Pick-Up</Step.Title>
        </Step.Content>
      </Step>
      <Step
        name="step3"
        active={step === 3}
        completed={step > 3}
        disabled={3 > step}
      >
        <Icon name="bus" />
        <Step.Content>
          <Step.Title>Destination</Step.Title>
        </Step.Content>
      </Step>
      <Step
        name="step4"
        active={step === 4}
        completed={step > 4}
        disabled={4 > step}
      >
        <Icon name="bus" />
        <Step.Content>
          <Step.Title>Bus Specs</Step.Title>
        </Step.Content>
      </Step>
      <Step
        name="step5"
        active={step === 5}
        completed={step > 5}
        disabled={5 > step}
      >
        <Icon name="payment" />
        <Step.Content>
          <Step.Title>Billing</Step.Title>
        </Step.Content>
      </Step>

      <Step
        name="finalstep"
        active={6 === step}
        completed={step > 6}
        disabled={6 > step}
      >
        <Icon name="info" />
        <Step.Content>
          <Step.Title>Confirm Order</Step.Title>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}
