import React, { useState } from "react";
import { Menu, Image, Visibility, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Media } from "./media";

import PacerLogo from "../img/pacerTransparentcanva.png";
export function NavigationMenu(props) {
  const [activeItem, setActiveItem] = useState("");
  return (
    <Media greaterThan="mobile">
      <Visibility once={false}>
        <Menu pointing secondary>
          <Container>
            <Menu.Item onClick={() => setActiveItem("home")} as={Link} to="/">
              <Image alt="logo" src={PacerLogo} className="logo" />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item
                name="home"
                active={activeItem === "home"}
                onClick={() => setActiveItem("home")}
                as={Link}
                to="/"
              />
              <Menu.Item
                name="requestBus"
                active={activeItem === "requestBus"}
                onClick={() => setActiveItem("requestBus")}
                as={Link}
                to="/requestBus"
              />
              <Menu.Item
                name="contact"
                active={activeItem === "contact"}
                onClick={() => setActiveItem("contact")}
                as={Link}
                to="/contactUs"
              />
              <Menu.Item
                name="about"
                active={activeItem === "about"}
                onClick={() => setActiveItem("about")}
                as={Link}
                to="/about"
              />
              <Menu.Item
                name="careers"
                active={activeItem === "careers"}
                onClick={() => setActiveItem("careers")}
                as={Link}
                to="/careers"
              />
            </Menu.Menu>
          </Container>
        </Menu>
        {props.children}
      </Visibility>
    </Media>
  );
}
