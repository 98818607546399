import React from "react";
import { Form, Header } from "semantic-ui-react";
import { CustomForm, InputCustom } from "./newRequestForm";
import { GoBackBtn } from "../gobackbtn";
import "./requestFormStyles.css";

export function Destination(props) {
  return (
    <CustomForm
      stepchanger={() => props.onStepChange()}
      className="busRequestForm"
    >
      <Header as="h3">Destination details, arrival and departure times.</Header>
      <label>Destination</label>
      <InputCustom name="destinationName" labeltitle="Destination Name" />
      <InputCustom
        name="destinationStreetAddress"
        labeltitle="Street Address"
      />
      <InputCustom name="destinationCity" labeltitle="City" />
      <InputCustom name="destinationZipCode" labeltitle="Zip Code" />
      <Form.Group widths="equal">
        <InputCustom
          name="destinationArrivalTime"
          labeltitle="Destination Arrival Time"
          type="time"
        />
        <InputCustom
          name="destinationDepartTime"
          labeltitle="Depart from Destination Time"
          type="time"
        />
        <InputCustom
          name="returnToOriginTime"
          labeltitle="Return to Origin Time"
          type="time"
        />
      </Form.Group>
      <GoBackBtn goBack={props.prevStep} actionName="Next" />
    </CustomForm>
  );
}
