import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Image,
  Segment,
  Grid,
  List,
  Divider,
  Icon,
  Header,
} from "semantic-ui-react";
import Logo from "../img/pacerTransparentcanva.png";

export function Footer() {
  return (
    <Segment inverted className="footer">
      <Container textAlign="center">
        <Grid columns={2} divided inverted>
          <Grid.Column textAlign="left">
            <List inverted>
              <List.Header>PACER ENTERPRISES INC</List.Header>
              <List.Item icon="phone" content="310.420.7880" />
              <List.Item
                icon="mail"
                content={
                  <a href="mailto:pacerenterprises@gmail.com">
                    pacerenterprises@gmail.com
                  </a>
                }
              />
            </List>
            <List inverted>
              <List.Header>OFFICE HOURS</List.Header>
              <List.Item content="8:00AM-6:00PM, M-F" />
              <List.Item content="8:00AM-2:00PM, Saturday" />
            </List>
          </Grid.Column>
          <Grid.Column textAlign="left">
            <Header as="h4">Follow us on social media:</Header>
            <Icon link circular inverted name="twitter"></Icon>
            <a
              href="http://www.instagram.com/pacerenterprisesinc"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <Icon link circular inverted name="instagram" />
            </a>
          </Grid.Column>
        </Grid>
        <Divider section inverted className="footer-divider" />
        <Image src={Logo} size="small" centered as={Link} to="/"></Image>
        <List horizontal link inverted divided size="small">
          <List.Item as={Link} to="/requestBus">
            Request A Bus
          </List.Item>
          <List.Item as={Link} to="/contactUs">
            Contact Us
          </List.Item>
          <List.Item as={Link} to="/about">
            About
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
}
