import React from "react";
import { Segment, Header, Grid } from "semantic-ui-react";

import PacerFleet2 from "../img/pacerFleet2.jpg";

export function About(props) {
  return (
    <>
      <div>
        <div
          className="welcome-header"
          style={{ backgroundImage: `url(${PacerFleet2})` }}
        >
          <div className="overlay-background"></div>
          <div className="centered-welcome">
            <Header as="h1">Who we are...</Header>
          </div>
        </div>
      </div>
      <Segment vertical className="intro-section">
        <Grid container stackable verticalAlign="middle">
          <Grid.Row centered>
            <Grid.Column>
              <Header as="h2">About Pacer Enterprises</Header>
              <p className="intro-section-paragraph">
                Pacer Enterprises is a family run school bus company that has
                been operating for more than 7 years. Our company strives to
                offer our clients the most reliable and safest rides around Los
                Angeles. Pacer drivers have gone through intense defensive
                driving courses and have all passed background, pre-employment
                and random drug testing. Pacer drivers are dedicated to
                transporting students to various destinations, from local parks
                and sporting events, to museums and from homes to shcools. Pacer
                is located just a mile away from the Los Angeles International
                Airport and two of the major freeways (405 and 105). We can get
                anywhere easily.
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
