import React from "react";
import { Header, Form } from "semantic-ui-react";
import { CustomForm, InputCustom } from "./newRequestForm";
import "./requestFormStyles.css";
import { GoBackBtn } from "../gobackbtn";

export function PickUp(props) {
  return (
    <CustomForm
      stepchanger={() => props.onStepChange()}
      className="busRequestForm step2"
    >
      <Header as="h3">When, where and what time are we picking you up?</Header>
      <InputCustom
        name="tripDate"
        labeltitle="Trip Date"
        type="date"
        defaultValue=""
      ></InputCustom>
      <label>Pick-Up Location</label>
      <InputCustom
        name="pickUpLocationName"
        labeltitle="Pick-Up Location Name"
      />
      <InputCustom
        name="pickUpStreetAddress"
        labeltitle="Street Address"
      ></InputCustom>
      <Form.Group widths="equal">
        <InputCustom name="pickupCity" labeltitle="City"></InputCustom>
        <InputCustom name="pickupZipCode" labeltitle="Zip Code"></InputCustom>
      </Form.Group>
      <Form.Group widths="equal">
        <InputCustom
          name="originArrivalTime"
          labeltitle="Arrival to Origin Time"
          type="time"
        />
        <InputCustom
          name="originDepartureTime"
          labeltitle="Departure from Origin Time"
          type="time"
        />
      </Form.Group>
      <GoBackBtn goBack={props.prevStep} actionName="Next" />
    </CustomForm>
  );
}
