import React from "react";
import { Form, Container, Header } from "semantic-ui-react";
import emailjs from "@emailjs/browser";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  InputCustom,
  CustomSelect,
} from "../components/busRequestForm/newRequestForm";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";

export function DriverApplication() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  function sendEmail(data) {
    emailjs
      .send(
        `${process.env.REACT_APP_EMAIL_JS_SERVICE}`,
        `${process.env.REACT_APP_EMAIL_JS_JOB_APP}`,
        data,
        `${process.env.REACT_APP_EMAIL_JS}`
      )
      .then(
        (result) => {
          if (result.status === 200) {
            navigate("/driverAppThankyou");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    fetch("../../../.netlify/functions/job-app", {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(() => alert("Form Sent!"))
      .catch((error) => alert(error));
  }
  const onSubmit = (data) => {
    sendEmail(data);
  };

  return (
    <Container className="driverApplication">
      <div className="driver-application-container">
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="driver-application-form"
        >
          <Header as="h3">A little about yourself...</Header>
          <Form.Group widths="equal">
            <InputCustom
              name="firstName"
              labeltitle="First Name"
              control={control}
              errors={errors}
            />
            <InputCustom
              name="lastName"
              labeltitle="Last Name"
              control={control}
              errors={errors}
            />
          </Form.Group>
          <Controller
            control={control}
            name="phoneNumber"
            rules={{
              validate: (value) => isValidPhoneNumber(value),
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <label>
                Phone Number
                <PhoneInput
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  international={false}
                  defaultCountry="US"
                />
              </label>
            )}
          />
          <InputCustom
            name="email"
            labeltitle=" Email Address"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="licenseType"
            labeltitle="Do you have either a Class B or Class A license?"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="schoolBusCert"
            labeltitle="Do you have a school bus driver certificate?"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="airBrakes"
            labeltitle="Do you have air brakes cert?"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="passengerEndorsement"
            labeltitle="Do you have a passenger endorsement certificate?"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="firstAid"
            labeltitle="Do you have a current first aid certificate?"
            control={control}
            errors={errors}
          />
          <CustomSelect
            name="medical"
            labeltitle="Do you have a current medical examiner's certificate?"
            control={control}
            errors={errors}
          />
          <button type="submit">Submit</button>
        </Form>
      </div>
    </Container>
  );
}
