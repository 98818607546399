import React, { useState } from "react";
import {
  Container,
  Divider,
  Segment,
  Icon,
  Header,
  Button,
  Grid,
  Image,
  Accordion,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import WaltHallBus3 from "../img/busWaltHall3.jpg";
import Hiring from "../img/pacerHiring.jpg";

export function Careers(props) {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="hiring-header">
        <Image src={Hiring} centered fluid />
        <div className="hiring-button">
          <Button
            size="huge"
            className="request-button"
            as={Link}
            to="/driverApplication"
          >
            Apply Now
          </Button>
        </div>
      </div>
      <Segment
        basic
        padded="very"
        vertical
        className="career-page-info-section"
      >
        <Grid container stackable verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width="8">
              <Header as="h3">
                Pacer is a family owned company and when you join the team you
                become part of our family.
              </Header>
              <p className="intro-section-paragraph">
                Are you looking to make a real impact in people’s lives? We’re
                looking for people like you to join our growing team that helps
                students and faculty reach their destinations safely.
              </p>
              <Header as="h3">See Los Angeles</Header>
              <p className="intro-section-paragraph">
                You'll be able to drive students to unique destinations
                throughout Los Angeles.
              </p>
            </Grid.Column>
            <Grid.Column width="6" floated="right">
              <Image src={WaltHallBus3} size="large"></Image>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Container>
        <Divider horizontal>
          <Header as="h3"> FAQ's</Header>
        </Divider>
      </Container>

      <Segment vertical className="business-info-section">
        <Grid container>
          <Grid.Row>
            <Accordion className={`intro-section-paragraph`} styled fluid>
              <Accordion.Title
                onClick={handleClick}
                index={0}
                active={activeIndex === 0}
              >
                <Icon
                  name={activeIndex === 0 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                What is Pacer?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <p>
                  We are a family owned school bus company that has been
                  operating in the Los Angeles area for over 7 years.
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={1}
                active={activeIndex === 1}
              >
                <Icon
                  name={activeIndex === 1 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                What role does a Pacer School Bus Driver play?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                <p>
                  You are an integral part of the Los Angeles community because
                  you will be providing safe and reliable transportation for our
                  Los Angelenos.
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={2}
                active={activeIndex === 2}
              >
                <Icon
                  name={activeIndex === 2 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                How do I apply?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                <p>
                  Apply online <Link to="/driverApplication"></Link>
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={3}
                active={activeIndex === 3}
              >
                <Icon
                  name={activeIndex === 3 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                Do I need to have a commercial driver's license (CDL)?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 3}>
                <p>
                  Yes. However, if you are not a Certified School Bus Driver,
                  Pacer will pay for your certificate training.
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={4}
                active={activeIndex === 4}
              >
                <Icon
                  name={activeIndex === 4 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                How many hours does a Pacer driver work?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <p>Pacer drivers average more than 8 hours per day.</p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={5}
                active={activeIndex === 5}
              >
                <Icon
                  name={activeIndex === 5 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                Do you offer overtime?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 5}>
                <p>
                  Yes, we offer overtime as it becomes available. Overtime can
                  average up to 10 hours per week.
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={6}
                active={activeIndex === 6}
              >
                <Icon
                  name={activeIndex === 6 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                Do you offer any benefits?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 6}>
                <p>
                  Yes. As a Pacer driver you will get 5 days of paid vacation
                  time, a 401(K), paid sick time, bonuses and paid Holidays.
                </p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={7}
                active={activeIndex === 7}
              >
                <Icon
                  name={activeIndex === 7 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                What is the pay rate for Pacer drivers?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 7}>
                <p>You will start at $26.00/hr.</p>
              </Accordion.Content>
              <Accordion.Title
                onClick={handleClick}
                index={8}
                active={activeIndex === 8}
              >
                <Icon
                  name={activeIndex === 8 ? "circle minus" : "circle plus"}
                  size="big"
                  className="plusfaq"
                />
                Will I work through the summer and winter?
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 8}>
                <p>Yes, Pacer drivers work year round.</p>
              </Accordion.Content>
            </Accordion>
          </Grid.Row>
        </Grid>
      </Segment>
      <Container>
        <Divider horizontal>
          <Header as="h3">
            <Icon name="bus" />
          </Header>
        </Divider>
      </Container>

      <Segment vertical className="intro-section">
        <Grid container textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Ready to become part of the Pacer family?</Header>
              <p className="intro-section-paragraph">
                Apply to be a Pacer School Bus Driver.
              </p>
              <div>
                <Button
                  size="huge"
                  className="request-button"
                  as={Link}
                  to="/driverApplication"
                >
                  Apply Now
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
}
