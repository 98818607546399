import React, { useState } from "react";
import { Header, Segment, Grid, Icon } from "semantic-ui-react";
import { format, parse, parseISO } from "date-fns";
import { useStateMachine } from "little-state-machine";
import { CustomForm, CheckboxCustom } from "./newRequestForm";
import updateAction from "./updateAction";
import { GoBackBtn } from "../gobackbtn";
import { formatPhoneNumber } from "react-phone-number-input";

export function FinalStep(props) {
  const { state } = useStateMachine({ updateAction });
  const [formData] = useState(state);

  const formatTripTimes = (times) => {
    return format(parse(times, "HH:mm", new Date()), "hh:mm a");
  };

  return (
    <Segment.Group>
      <Header as="h3">Trip Summary</Header>
      {formData && (
        <Grid stackable className="order-review-page">
          <Grid.Row>
            <Grid.Column width={8}>
              <div>
                <Header as="h3">
                  Trip Date:{" "}
                  {format(parseISO(formData.tripDate), "EEEE  MMMM dd, Y")}
                </Header>
              </div>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <div>
                <Header as="h3">
                  Ordered By: {formData.firstName} {formData.lastName}
                </Header>
                <div>
                  <Icon.Group size="large">
                    <Icon name="phone square" />
                  </Icon.Group>{" "}
                  {formatPhoneNumber(formData.phoneNumber)}
                </div>
                <div>
                  <Icon.Group size="large">
                    <Icon name="mail" />
                  </Icon.Group>{" "}
                  {formData.email}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Segment raised>
                <Grid columns={3}>
                  <Grid.Row verticalAlign="middle" stretched>
                    <Grid.Column floated="right">
                      <div>
                        <p>
                          {formData &&
                            formatTripTimes(formData.originArrivalTime)}{" "}
                          Passenger Loading
                        </p>
                        <p>
                          {formData &&
                            formatTripTimes(formData.originDepartureTime)}{" "}
                          Departure
                        </p>
                        <p>{formData.pickUpStreetAddress}</p>
                        <p>
                          {formData.pickupCity}, {formData.pickupZipCode}
                        </p>
                      </div>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <div>
                        <Icon.Group size="huge">
                          <Icon name="long arrow alternate right" />
                          <Icon corner="bottom left" name="clock outline" />
                        </Icon.Group>
                      </div>
                    </Grid.Column>
                    <Grid.Column floated="left">
                      <div>
                        <p>
                          {formatTripTimes(
                            formData && formData.destinationArrivalTime
                          )}{" "}
                          Arrival
                        </p>
                        <p>{formData.destinationStreetAddress}</p>
                        <p>
                          {formData.destinationCity},{" "}
                          {formData.destinationZipCode}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment raised>
                <Grid padded columns="equal">
                  <Grid.Row verticalAlign="middle">
                    <Grid.Column width={7}>
                      <div>
                        <p>
                          {formatTripTimes(
                            formData && formData.returnToOriginTime
                          )}{" "}
                          Arrival
                        </p>
                        <p>{formData.pickUpStreetAddress}</p>
                        <p>
                          {formData.pickupCity}, {formData.pickupZipCode}
                        </p>
                      </div>
                    </Grid.Column>
                    <Grid.Column textAlign="center" width={2}>
                      <div>
                        <Icon.Group size="huge">
                          <Icon name="long arrow alternate left" />
                          <Icon corner name="clock outline" />
                        </Icon.Group>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <div>
                        <p>
                          {formatTripTimes(
                            formData && formData.destinationDepartTime
                          )}{" "}
                          Departure
                        </p>
                        <p>{formData.destinationStreetAddress}</p>
                        <p>
                          {formData.destinationCity},{" "}
                          {formData.destinationZipCode}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <Grid.Column width={8}>
              <Segment raised>
                <Header as="h4" textAlign="center">
                  Bus Specs
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={10}>
                      <div>
                        <p>Amnt of buses:</p>
                        <p>Amnt of students:</p>
                        <p>Amnt of adults:</p>
                        <p>Seatbelts needed:</p>
                        <p>Storage needed:</p>
                        <p>Sport or Group Type, Grade:</p>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <div>
                        <p>{formData.amntOfBuses}</p>
                        <p>{formData.amntOfStudents}</p>
                        <p>{formData.amntOfAdults}</p>
                        <p>{formData.seatBelts === "true" ? "YES" : "NO"}</p>
                        <p>{formData.storage === "true" ? "YES" : "NO"}</p>
                        <p>{formData.groupType}</p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment raised>
                <Header as="h4" textAlign="center">
                  Billing Info
                </Header>
                <Grid>
                  <Grid.Row stretched>
                    <Grid.Column width={6}>
                      <div>
                        <p>Email:</p>
                        <p>Organization Name:</p>
                        <p>Attention To:</p>
                        <p>Address:</p>
                      </div>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <div>
                        <p>{formData.billingEmail}</p>
                        <p>{formData.organizationName}</p>
                        <p>{formData.attentionToName}</p>
                        <p>
                          {formData.billingStreetAdress}, <br />{" "}
                          {formData.billingCity}, {formData.billingZipcode}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <CustomForm className="finalStepSubmit">
        <CheckboxCustom name="agreedToTerms" />
        <GoBackBtn goBack={props.prevStep} actionName="Submit" />
      </CustomForm>
    </Segment.Group>
  );
}
