import React from "react";
import { Header, Form } from "semantic-ui-react";
import { CustomForm, CustomPhoneInput, InputCustom } from "./newRequestForm";
import "./requestFormStyles.css";

export function Contact(props) {
  return (
    <CustomForm
      stepchanger={() => props.onStepChange()}
      className="busRequestForm step1"
    >
      <Header as="h3">Contact Information...</Header>
      <Form.Group widths="equal">
        <InputCustom name="firstName" labeltitle="First Name" />
        <InputCustom name="lastName" labeltitle="Last Name" />
      </Form.Group>
      <CustomPhoneInput name="phoneNumber" labeltitle="Phone Number" />
      <InputCustom name="email" labeltitle="Email" />
      <button type="submit">Next</button>
    </CustomForm>
  );
}
