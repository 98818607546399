import React from "react";
import { Container } from "semantic-ui-react";
export function ContactPageSubmission() {
  return (
    <Container text className="busSubmissionThankyou">
      <h2>THANK YOU FOR CONTACTING US</h2>
      <p>We will review your submission and contact you shortly. Thank you.</p>
    </Container>
  );
}
