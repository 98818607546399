import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "fomantic-ui-css/semantic.css";
import { NhostClient } from "@nhost/nhost-js";
import { NhostApolloProvider } from "@nhost/react-apollo";

const nhost = new NhostClient({
  backendUrl: `https://${process.env.REACT_APP_NHOST}.nhost.run`,
});

ReactDOM.render(
  <React.StrictMode>
    <NhostApolloProvider nhost={nhost}>
      <App />
    </NhostApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
