import React from "react";
import { Form, Container, Header, Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import updateAction from "./busRequestForm/updateAction";
import { useStateMachine } from "little-state-machine";
import { useForm } from "react-hook-form";

const options = [
  { value: "Please select one...", label: "0" },
  { value: "I have a question before I reserve a bus", label: "2" },
  { value: "I have a billing question", label: "3" },
  { value: "Other", label: "4" },
];
export function ContactForm() {
  const { register, handleSubmit } = useForm();
  const { actions } = useStateMachine({ updateAction });
  const navigate = useNavigate();

  function sendEmail(data) {
    emailjs
      .send(
        `${process.env.REACT_APP_EMAIL_JS_SERVICE}`,
        `${process.env.REACT_APP_EMAIL_JS_CONTACT_US}`,
        data,
        `${process.env.REACT_APP_EMAIL_JS}`
      )
      .then(
        (result) => {
          if (result.status === 200) {
            navigate("/contactThankYou");
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  }
  const onSubmit = (data) => {
    actions.updateAction(data);
    sendEmail(data);
  };

  return (
    <Container>
      <div className="contact-form-container">
        <Form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <Header className="contact-form-header">
            Smiling Folks, standing by.
          </Header>
          <p>
            Always humans, never autobots. The sharpest, friendliest support
            team in the bus biz. Contact us and we'll get back to you ASAP.
          </p>
          <Divider />
          <label>
            What do you need help with?
            <select {...register("subject", { required: true })}>
              {options.map((option, index) => {
                return (
                  <option value={option.value} key={index}>
                    {option.value}
                  </option>
                );
              })}
            </select>
          </label>
          <label>
            Message:
            <textarea {...register("message", { required: true })} />
          </label>
          <label>
            Email Address
            <input {...register("email", { required: true })} />
          </label>
          <button className="contact-form-btn" type="submit">
            Submit
          </button>
        </Form>
      </div>
    </Container>
  );
}
