import React from "react";
import { Form, Label } from "semantic-ui-react";
import { format, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import updateAction from "./updateAction";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import emailjs from "@emailjs/browser";
import { gql, useMutation } from "@apollo/client";

import { TermsAndConditions } from "../termsAndConditions";

import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";

export function CustomForm({ ...props }) {
  const { state, actions } = useStateMachine({ updateAction });
  const methods = useForm({ defaultValues: state, mode: "onChange" });
  const navigate = useNavigate();

  const {
    formState: { errors },
    control,
  } = methods;
  const insert_busrequest = gql`
    mutation ($objects: [busrequest_busrequest_insert_input!] = {}) {
      insert_busrequest_busrequest(objects: $objects) {
        affected_rows
      }
    }
  `;
  const [insertBusRequests] = useMutation(insert_busrequest);

  const submitRequest = async (data) => {
    const parseTripDate = format(parseISO(data.tripDate), "MM/dd/yyyy");
    const tripSubmittedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS");

    try {
      const busrequest = await insertBusRequests({
        variables: {
          objects: [
            {
              date_trip_submitted: tripSubmittedDate,
              additional_stop_city: data.additionalStopCity,
              additional_stop_street_address: data.additionalStopStreetAddress,
              additional_stop_zipcode: data.additionalStopZipCode,
              amnt_of_buses: data.amntOfBuses,
              amount_of_adults: data.amntOfAdults,
              amount_of_students: data.amntOfStudents,
              origin_departure_time: data.originDepartureTime,
              attention_to: data.attentionToName,
              billing_city: data.billingCity,
              billing_email: data.billingEmail,
              billing_street_address: data.billingStreetAdress,
              billing_zipcode: data.billingZipcode,
              cancellation_policy: true,
              client: {
                data: {
                  email: data.email,
                  first_name: data.firstName,
                  last_name: data.lastName,
                  phone_number: data.phoneNumber,
                },
              },
              destination_name: data.destinationName,
              destination_arrival_time: data.destinationArrivalTime,
              destination_city: data.destinationCity,
              destination_zip_code: data.destinationZipCode,
              destination_departure_time: data.destinationDepartTime,
              destination_street_address: data.destinationStreetAddress,
              trip_date: parseTripDate,
              storage: data.storage,
              special_instructions: data.specialInstructions,
              seat_belts: data.seatBelts,
              pick_up_location_name: data.pickUpLocationName,
              pick_up_zipcode: data.pickupZipCode,
              pick_up_street_address: data.pickUpStreetAddress,
              pick_up_city: data.pickupCity,
              origin_arrival_time: data.originArrivalTime,
              organization_name: data.organizationName,
              group_type: data.groupType,
              return_to_origin_time: data.returnToOriginTime,
              agreed_to_terms: data.agreedToTerms,
            },
          ],
        },
      });
      return busrequest;
    } catch (error) {
      console.error(error);
      return console.log("Error submitting form", error);
    }
  };
  function sendEmail(data) {
    const new_data = {
      ...data,
      tripDate: format(parseISO(data.tripDate), "EEEE  MMMM dd, Y"),
    };
    emailjs
      .send(
        `${process.env.REACT_APP_EMAIL_JS_SERVICE}`,
        `${process.env.REACT_APP_EMAIL_JS_BUS_REQUEST}`,
        new_data,
        `${process.env.REACT_APP_EMAIL_JS}`
      )
      .then(
        (result) => {
          if (result.status === 200) {
            navigate("/thankyou");
          }
        },
        (error) => {
          console.log("error sending email", error.text);
        }
      );
  }
  const onSubmit = (data) => {
    actions.updateAction(data);

    if (props.className === "finalStepSubmit") {
      sendEmail(data);
      submitRequest(data);
    } else {
      props.stepchanger();
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="requestFormComponent"
        {...props}
      >
        {Array.isArray(props.children)
          ? props.children.map((child) => {
              return child && child.props && child.props.name
                ? React.createElement(
                    "label",
                    { ...child.props, key: child.props.name },
                    React.createElement(child.type, {
                      ...{
                        ...child.props,
                        register: methods.register,
                        control,
                        errors,
                        key: child.props.name,
                      },
                    })
                  )
                : child;
            })
          : props.children}
      </Form>
    </FormProvider>
  );
}

export const InputCustom = React.forwardRef(
  ({ type, labeltitle, errors, control, name }, ref) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={{
            required: `${labeltitle} is required`,
          }}
          defaultValue=""
          render={({ field }) => (
            <Form.Field>
              <Form.Input label={labeltitle} {...field} type={type} />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <div className="error">{message}</div>}
              />
            </Form.Field>
          )}
        />
      </>
    );
  }
);

export const BusSpecSelect = React.forwardRef(
  ({ labeltitle, errors, control, name }, ref) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          rules={{
            required: `${labeltitle} is required`,
          }}
          defaultValue=""
          render={({ field }) => (
            <>
              <Form.Field
                label={labeltitle}
                control="select"
                ref={ref}
                onChange={field.onChange}
                {...field}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Form.Field>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <p className="error">{message}</p>}
              />
            </>
          )}
        />
      </>
    );
  }
);

export function CustomPhoneInput({ labeltitle, errors, control, name }) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) =>
            isValidPhoneNumber(value) ? null : "Invalid Phone Number",
          required: `${labeltitle} is required`,
        }}
        render={({ field: { onChange, value } }) => (
          <>
            {labeltitle}
            <PhoneInput
              value={value}
              onChange={onChange}
              international={false}
              defaultCountry="US"
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => <p className="error">{message}</p>}
            />
          </>
        )}
      />
    </>
  );
}

export const DateInput = React.forwardRef(
  ({ labeltitle, onChange, onBlur, name, register, errors, ...rest }, ref) => (
    <>
      {labeltitle}
      <input
        type="date"
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        {...register(name, {
          valueAsDate: true,
          required: true,
        })}
        {...rest}
      ></input>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="error">{message}</p>}
      />
    </>
  )
);

export const CustomSelect = React.forwardRef(
  ({ labeltitle, errors, control, name }, ref) => {
    return (
      <>
        <Controller
          name={name}
          control={control}
          defaultValue=""
          rules={{
            required: `A selection is required`,
          }}
          render={({ field }) => (
            <>
              <Form.Field
                label={labeltitle}
                control="select"
                ref={ref}
                onChange={field.onChange}
                {...field}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Form.Field>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <p className="error">{message}</p>}
              />
            </>
          )}
        />
      </>
    );
  }
);

export function TextArea({ labeltitle, register, options, name, ...rest }) {
  return (
    <>
      {labeltitle}
      <textarea {...register(name)} {...rest} />
    </>
  );
}

export function CheckboxCustom({ control, name, errors }) {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={{
          required: `You must agree to the terms and conditions`,
        }}
        render={({ field }) => (
          <>
            <Form.Field inline>
              <input type="checkbox" onChange={field.onChange} {...field} />
              <Label pointing="left" tag as="a" color="blue">
                I Agree to the <TermsAndConditions />
              </Label>
            </Form.Field>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => <p className="error">{message}</p>}
            />
          </>
        )}
      />
    </>
  );
}
