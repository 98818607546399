import React from "react";
import { SideBarMenu } from "./sideBarMenu";
import { NavigationMenu } from "./navigationMenu";
import { MediaContextProvider } from "./media";

export function ResponsiveContainer(props) {
  return (
    <MediaContextProvider>
      <NavigationMenu>{props.children}</NavigationMenu>
      <SideBarMenu>{props.children}</SideBarMenu>
    </MediaContextProvider>
  );
}
