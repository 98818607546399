import React, { useState } from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import { FormSteps } from "./formSteps";
import { Contact } from "./busRequestForm/contact";
import { PickUp } from "./busRequestForm/pickUp";
import { Destination } from "./busRequestForm/destination";
import { BusSpecs } from "./busRequestForm/busSpecs";
import { BillingInfo } from "./busRequestForm/billingInfo";
import { FinalStep } from "./busRequestForm/finalStep";

export function RequestBus(props) {
  const [step, setStep] = useState({ step: 1 });
  const nextStep = () => {
    setStep({ step: step.step + 1 });
  };
  const prevStep = () => {
    setStep({ step: step.step - 1 });
  };
  return (
    <Container className="request-form-segment">
      <div className="request-form-container">
        <Header as="h1">Request A Bus</Header>
        <Header as="h2">Fill out the bus request form to get a quote.</Header>
        <Grid padded centered>
          <Grid.Row>
            <Grid.Column>
              <div>
                <FormSteps currentStep={step} />
                {step.step === 1 && <Contact onStepChange={nextStep} />}
                {step.step === 2 && (
                  <PickUp onStepChange={nextStep} prevStep={prevStep} />
                )}
                {step.step === 3 && (
                  <Destination onStepChange={nextStep} prevStep={prevStep} />
                )}
                {step.step === 4 && (
                  <BusSpecs onStepChange={nextStep} prevStep={prevStep} />
                )}
                {step.step === 5 && (
                  <BillingInfo onStepChange={nextStep} prevStep={prevStep} />
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Container>
          {step.step === 6 && (
            <FinalStep onStepChange={setStep} prevStep={prevStep} />
          )}
        </Container>
      </div>
    </Container>
  );
}
